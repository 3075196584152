// import { useState } from 'react'
import { Link } from 'gatsby'


/** @jsx jsx */
import {
    jsx,
    Box, 
    Flex, 
    Button,
    Label,
    Input,
    Textarea,
    Checkbox,
  } from 'theme-ui'

import { MdPhone, MdEmail, MdBusiness, MdAccountCircle, MdAssignmentInd, MdInsertLink, MdEdit, MdPlace, MdInsertComment, MdSend } from "react-icons/md"

const Form = (props) => {
    const {
        cart, 
        toggleCartItem,
        products,
    } = props

    // const formDefault = {
    //     first_name: '',
    //     last_name: '',
    //     email: '',
    //     phone: '',
    //     company: '',
    //     website: '',
    //     corporate_email: '',
    //     corporate_phone: '',
    //     pitch: '',
    //     address: '',
    // }
    
    // const [formValues, setFormValues] = useState(formDefault)
    // const [success, setSuccess] = useState(false)

    // const handleInputChange = (e) => {
    //     e.persist()
    //     setFormValues(prevState => ({
    //         ...prevState,
    //         [e.target.name]: e.target.value,
    //     }))
    // }

    // const handleSubmit = (e) => {
    //     // e.preventDefault()
    //     // Submit form
    //     // alert(`Submitting ${JSON.stringify(formValues)}`)
    //     // Reset to default
    //     setSuccess(true)
    //     setFormValues(formDefault)
    // }

    return (
        <Box
            as='form'
            // onSubmit={handleSubmit}
            name="Referencement" 
            method="POST"
            netlify-honeypot="bot-field"
            data-netlify="true"
            action="/merci"
        >
            <h3 sx={{mt: 0, mb: 2, fontSize: 5, fontFamily: 'heading', fontWeight: 'bold',}}>Inscription gratuite</h3>
            <p sx={{mt: 0, mb: 4}}>Remplissez le formulaire pour vous inscrire gratuitement. Si vous souhaitez bénéficier des nos formules premium pour donner plus de visibilité à votre entreprise, cochez les cases "Add-ons" pour recevoir l'offre. Notre équipe basée à Lausanne reviendra vers vous dans les meilleurs délais.</p>

            <input type="hidden" name="bot-field" aria-label="bot field" />
            <input type="hidden" name="form-name"  aria-label="form name" value="Referencement" />

            <div sx={formContainer}>
                <h4 sx={{my: 0, fontSize: 4, fontWeight: 'body',}}><MdAccountCircle sx={{mb: '-3px',}} /> Vos informations de contact <b>personnelles</b></h4>

                <Flex sx={flexContainer}>
                    <Box sx={flexTwoColumns}>
                        <Label htmlFor='first_name' sx={{alignItems: 'center',}}><MdAssignmentInd sx={{mr: 1,}} /> Prénom *</Label>
                        <Input
                            name='first_name'
                            id='first_name'
                            // value={formValues.first_name}
                            // onChange={handleInputChange}
                            required
                        />
                    </Box>
    
                    <Box sx={flexTwoColumns}>
                        <Label htmlFor='last_name' sx={{alignItems: 'center',}}><MdAssignmentInd sx={{mr: 1,}} /> Nom *</Label>
                        <Input
                            name='last_name'
                            id='last_name'
                            // value={formValues.last_name}
                            // onChange={handleInputChange}
                            required
                        />
                    </Box>
                </Flex>

                <Flex sx={flexContainer}>
                    <Box sx={flexTwoColumns}>
                        <Label htmlFor='email' sx={{alignItems: 'center',}}><MdEmail sx={{mr: 1,}} /> Votre email pro *</Label>
                        <Input
                            type="email"
                            name='email'
                            id='email'
                            // value={formValues.email}
                            // onChange={handleInputChange}
                            required
                        />
                    </Box>
                    <Box sx={flexTwoColumns}>
                        <Label htmlFor='phone' sx={{alignItems: 'center',}}><MdPhone sx={{mr: 1,}} /> Votre numéro de téléphone pro *</Label>
                        <Input
                            type="tel"
                            name='phone'
                            id='phone'
                            // value={formValues.phone}
                            // onChange={handleInputChange}
                            required
                        />
                    </Box>
                </Flex>
            </div>

            <div sx={formContainer}>
                <h4 sx={{mt: 0, mb: 0, fontSize: 4, fontWeight: 1,}}><MdBusiness sx={{mb: '-3px',}} /> Les informations de votre <b>entreprise</b></h4>

                <Flex sx={flexContainer}>
                    <Box sx={flexTwoColumns}>
                        <Label htmlFor='company' sx={{alignItems: 'center',}}><MdEdit sx={{mr: 1,}} /> Nom de l'entreprise *</Label>
                        <Input
                            type='text'
                            name='company'
                            id='company'
                            // value={formValues.company}
                            // onChange={handleInputChange}
                            required
                        />
                    </Box>
    
                    <Box sx={flexTwoColumns}>
                        <Label htmlFor='website' sx={{alignItems: 'center',}}><MdInsertLink sx={{mr: 1,}} /> Site web</Label>
                        <Input
                            type="url"
                            name='website'
                            id='website'
                            // value={formValues.website}
                            // onChange={handleInputChange}
                        />
                    </Box>
                </Flex>

                <Flex sx={flexContainer}>
                    <Box sx={flexTwoColumns}>
                        <Label htmlFor='corporate_email' sx={{alignItems: 'center',}}><MdEmail sx={{mr: 1,}} /> Email de contact de l'entreprise *</Label>
                        <Input
                            type="email"
                            name='corporate_email'
                            id='corporate_email'
                            // value={formValues.corporate_email}
                            // onChange={handleInputChange}
                            required
                        />
                    </Box>
                    <Box sx={flexTwoColumns}>
                        <Label htmlFor='corporate_phone' sx={{alignItems: 'center',}}><MdPhone sx={{mr: 1,}} /> Téléphone de l'entreprise *</Label>
                        <Input
                            type="tel"
                            name='corporate_phone'
                            id='corporate_phone'
                            // value={formValues.corporate_phone}
                            // onChange={handleInputChange}
                            required
                        />
                    </Box>
                </Flex>

                <Label htmlFor='pitch' sx={{alignItems: 'center',}} ><MdInsertComment sx={{mr: 1,}} /> Description (pitch) en 3 lignes *</Label>
                <Textarea
                    name='pitch'
                    id='pitch'
                    rows='6'
                    mb={3}
                    // value={formValues.pitch}
                    // onChange={handleInputChange}
                    required
                />
    
                <Label htmlFor='Address' sx={{alignItems: 'center',}}><MdPlace sx={{mr: 1,}} /> Adresse(s) de l'entreprise *</Label>
                <Textarea
                    name='address'
                    id='address'
                    rows='6'
                    mb={3}
                    // value={formValues.address}
                    // onChange={handleInputChange}
                    required
                />
            </div>
            
            <div sx={formContainer}>
                <h4 sx={{m: 0, fontSize: 4, fontWeight: 1,}}>Ajoutez des <b>Add-Ons</b> <i>(optionnel)</i></h4>
                <p>Améliorer votre fiche gratuite, boostez votre visibilité dans le listing, et différenciez-vous en ajoutant des informations et contenus multi-média additionnels et en bénéficiant de l'accompagnement de notre équipe.</p>
                <div mb={3} >
                    {products.map(product => {
                        // console.log("PRODUCT IN CART", cart.has(product.id) ? "YES" : "NO")
                        // console.log("RENDER CHECKBOX")
                        console.log("product", product)

                        return (
                            <Label key={product.name}>
                                <Checkbox 
                                    name={product.name} 
                                    // value={`${product.id}-${product.name}`}
                                    checked={cart[product.level] ? true : false}
                                    onChange={() => toggleCartItem(product.level)}
                                /> {product.name} (<span>{product.price}</span>) <a href={`#${product.id}`} sx={{color: 'text', ml: 1}}>Voir plus</a>
                            </Label>
                        )
                    })}
                </div>
            </div>

            <Label sx={{
                a: {
                    mx: 2,
                }
            }}>
                <Checkbox 
                        name="consent"
                        required
                /> J'ai lu et j'accepte la <Link to='/politique-de-confidentialite' sx={{color: 'text'}}>Politique de confidentialité</Link> et les <Link to='/conditions-dutilisation' sx={{color: 'text'}}>Conditions d'utilisation</Link>
            </Label>

            <Button
                type="submit" aria-label="Envoyer"
                sx={{
                    cursor: 'pointer',
                }}
                className="button"
                data-button="form-submit"
            >
                <MdSend /> Envoyer la demande
            </Button>
        </Box>
    )
}

export default Form

const flexContainer = { 
    justifyContent: 'space-between', 
    flexWrap: 'wrap' 
}

const flexTwoColumns = { 
    maxWidth: ['100%', '100%', '48%'],
    flex: '1 1 auto',
}

const formContainer = {
    p: 4,
    bg: 'muted',
    borderRadius: 1,
    mt: 0, mb: 3,
}